import { render, staticRenderFns } from "./AddressList.vue?vue&type=template&id=09a337ca&scoped=true&"
import script from "./AddressList.vue?vue&type=script&lang=js&"
export * from "./AddressList.vue?vue&type=script&lang=js&"
import style0 from "./AddressList.vue?vue&type=style&index=0&id=09a337ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09a337ca",
  null
  
)

export default component.exports