<template>
  <div class="flex-auto column" style="margin-bottom: 0;">
    <div class="flex-row align-items-center justify-between padding-bottom-10 border-bottom">
      <div class="flex-row align-items-center">
        <div class="bold text-nowrap margin-right-10">地址管理</div>
        <el-input v-model="keyword" placeholder="请输入搜索关键字" clearable @clear="init">
          <el-button slot="append" icon="el-icon-search" @click="init"></el-button>
        </el-input>
      </div>
      
      <el-button type="primary" size="medium" @click="addressFormShow">添加地址</el-button>
    </div>
    <div class="table-vertical-top table-cell-padding-none margin-top-10" ref="main">
      <el-table
        v-if="listInfo.list.length"
        :show-header="false"
        :data="listInfo.list"
        border
        stripe
        style="width: 100%"
      >
      <el-table-column width="100">
        <template slot-scope="props">
          <div class="flex-row align-items-center font-size-12 padding-lr-10 bold" style="height: 30px;">收货人</div>
          <div class="padding-10">{{props.row.user_name}}</div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="props">
          <div class="flex-row align-items-center font-size-12 padding-lr-10 bold" style="height: 30px;">所在地区</div>
          <div class="padding-10">{{props.row.province}}{{props.row.city}}{{props.row.district}}</div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="props">
          <div class="flex-row align-items-center font-size-12 padding-lr-10 bold" style="height: 30px;">详细地址</div>
          <div class="padding-10">{{props.row.address_detail}}</div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="props">
          <div class="flex-row align-items-center font-size-12 padding-lr-10 bold" style="height: 30px;">电话/手机</div>
          <div class="padding-10">{{props.row.user_phone}}</div>
        </template>
      </el-table-column>
      <el-table-column width="100">
        <template slot-scope="props">
          <div class="flex-row align-items-center font-size-12 padding-lr-10 bold" style="height: 30px;">操作</div>
          <div class="padding-10 flex-row text-nowrap">
            <div class="link-text margin-right-10" @click="addressFormShow(props.row)">编辑</div>| 
            <div class="link-text margin-left-10" @click="delAddress(props.row)">删除</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="props">
          <div class="flex-row align-items-center font-size-12 padding-lr-10 bold" style="height: 30px;">&nbsp;</div>
          <div class="padding-10">
            <el-tag v-if="props.row.is_default">默认地址</el-tag>
            <div v-else class="link-text" @click="setDefault(props.row,1)">设为默认</div>
          </div>
        </template>
      </el-table-column>
      </el-table>
      <div v-if="listInfo.loading" class="text-align-center color-sub margin-tb-10"><i class="el-icon-loading"></i> 加载中</div>
      <div v-if="listInfo.noMore&&listInfo.list.length" class="text-align-center color-sub margin-tb-10">没有更多啦！</div>
      <el-empty v-if="!listInfo.list.length&&listInfo.page==1&&!listInfo.loading" description="暂无数据">
         <el-button type="primary" @click="addressFormShow()">添加地址</el-button>
      </el-empty>
    </div>
    <el-dialog custom-class="custom-dialog" :title="`${addressManage.type==0?'添加':'修改'}地址信息`"  :visible.sync="addressManage.visible" :append-to-body="true" width="50%" top="15vh">
      <div>
        <AddressForm :q="{...addressManage.form}" @success="updateList"></AddressForm>
        
      </div>
    </el-dialog>
  </div>
</template>

<script>
import crypto from "@/common/crypto";
import AddressForm from '@/components/AddressForm.vue'
export default {
  components: {AddressForm},
  name: 'AddressList',
  data() {
    return {
      crypto,
      keyword:'',
      listInfo:{
        loading:false,
        noMore:false,
        page:1,
        pageSize:20,
        list:[]
      },
      addressManage:{
        type:0,//0添加，1编辑
        visible:false,
        form:{}
      },
      bodyScrollHeight:0
    }
  },
  computed:{
    
  },
  methods:{
    init(){
      document.removeEventListener("scroll", this.loadMore);
      this.listInfo.noMore=false
      this.listInfo.page=1
      this.listInfo.list=[]
      this.bodyScrollHeight=0
      this.getList()
    },
    //获取列表
    getList(){
      this.listInfo.loading=true
      let params= {
        ...this.$store.state.basicParams,
        page:this.listInfo.page,
        page_size:this.listInfo.pageSize,
        keyword:this.keyword
      }
      this.$instance.get('/address/get_user_address_list',{params})
      .then(res=>{
        this.listInfo.loading=false
        if(res.data.code==0){
          this.listInfo.list=this.listInfo.list.concat(res.data.data.list||[])
          this.$nextTick(()=>{
            window.scrollTo(0,this.bodyScrollHeight)
          })
          //是否全部加载完成
          if(res.data.data.list.length<this.listInfo.pageSize){
            this.listInfo.noMore=true
          }else{this.listInfo.page+=1}
          //如果有更多增加滚动加载侦听
          if(this.listInfo.page==2){
            document.addEventListener('scroll', this.loadMore)
          }
        }
      })
      .catch(()=> {
        this.listInfo.loading=false
      }); 
    },
    //加载更多
    loadMore(){
      this.bodyScrollHeight=document.documentElement.scrollTop || document.body.scrollTop
      this.$root.scrollLoad({
        dom:this.$refs.main,
        allow:!this.listInfo.loading&&!this.listInfo.noMore,
        offset:0,
        callBack:this.getList,
      })
    },
    addressFormShow(item){
      this.addressManage={
        type:item?1:0,
        visible:true,
        form:item||{}
      }
    },
    
    //删除地址
    delAddress(item){
      this.$confirm('您确定要删除此地址信息吗?', '删除地址', {
        customClass:"custom-msgbox",
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        let params= {
          ...this.$store.state.basicParams,
          address_id:item.address_id
        }
        this.$instance.post('/address/delete_user_address',this.$qs.stringify(params))
        .then(res=>{
          if(res.data.code==0){
            this.listInfo.list.splice(this.listInfo.list.findIndex(v=>{return v.address_id==item.address_id}),1)
          }
        })
        .catch(()=> {
        }); 
        
      })
      .catch(() => {});
    },
    //设置默认地址
    setDefault(item,state){
      let params= {
        ...this.$store.state.basicParams,
        address_id:item.address_id,
        state:state
      }
      this.$instance.post('/address/set_default_user_address',this.$qs.stringify(params))
      .then(res=>{
        if(res.data.code==0){
          if(state){
            for(let v of this.listInfo.list){
              if(v.address_id==item.address_id){
                v.is_default=1
              }else{
                v.is_default=0
              }
            }
          }
        }
      })
      .catch(()=> {
      }); 
    },
    updateList(){
      this.init()
      this.addressManage.visible=false
    }
    
  },
  created(){
    this.getList()
  },
  mounted(){ 
    
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.loadMore);
  },
  watch: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 .list-item-pic{
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
  }
</style>
<style>

</style>
